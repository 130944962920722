.insights-container {
  .insights-view {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50%;
    .insight-report {
      iframe {
        height: 100% !important;
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }
    .mb-3 {
      margin-bottom: 0px !important;
    }
  }
}


.map-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}


.reports-panel {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;

  .incident-title {
    padding: 6px 15px;
    font-size: 16px;
    background: #fff;
    height: 40px;
    line-height: 40px;
  }
  .reports-list{
    position: absolute;
    top: 55px;
    bottom: 30px;
    left: 7px;
    right: 0;
    font-size: 12px;
    text-align: left;
    overflow: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    .hand-cursor {
        cursor: pointer;
    }
    .list{
        background: #fff;
        padding: 7px;
        margin-bottom: 7px;
        border: 1px solid #9b9b98;
        font-weight: 500;
        font-size: 12px;
        position: relative;
        min-height: 210px;
        line-height: 18px;
        overflow: auto;
        svg{
            stroke-width: .4;
        }
        &.active{
            outline: 2px solid #f99403;
            border-color: #f99403;
        }
        &.inProgress{
            background-color: rgb(130,196,237);
        }
        &.queued{
            background-color: rgb(214, 235, 252);
        }
        &.ended {
            background-color: rgb(210,208,203)
        }
        .address{
            font-size: 15px;
            clear: both;
            display: block;
            white-space: nowrap;
            padding-right: 7px;
        }
    }
    .refresh-icon{
        width: 20px;
    }
    .incident-tower{
        padding-left: 5px;
        font-size: 15px;
        .title{
            font-weight: bold;
            text-transform: capitalize;
            font-size: 12px;
        }
        img{
            width: 20px;
        }
    }
    .coordinates{
        font-size: 11px;
    }
    .more-info{
        position: absolute;
        bottom: 8px;
        left: 7px;
        right: 8px;
        .add-btn{
            background-color: #0067b3;
            color: #fff;
            padding: 0 15px;
            border-radius: 25px;
            height: 24px;
            line-height: 23px;
            // margin: 5px 0;
            font-size: 11px;
            display: inline-block;
            &.active{
                background-color: #999999;
            }
        }
        .uber{
            width: 30px;
            // margin-top: 5px;
            position: relative;
            left: -25px;
        }
    }
    .tower-icon{            
        img{
            width: 26px;
            &.landline{
                width: 25px;
            }
            &.others{
                width: 20px;
            }
        }
        .close{
            font-size: 14px;
        }
    }
    .print-icon{
        img{
            width: 21px;
            position: relative;
            // top: -2px;
        }
    }
    .unknown-loc{
        color: #ba0a0a;
        font-size: 16px;
    }
    }

    .report-search-bar {
        position: relative;
        input {
            padding: 9px 10px 10px 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            color: #000 !important;
            font-size: 14px;
            outline: none;
            width: 240px;
            transition: all 0.3s ease;
            left: 10px;
            position: relative;
            top: 5px;

            &:focus {
                border-color: #7800F7;
            }
          }
        .search-spinner {
            position: absolute;
            top: 40%;
            right: 15px;
        }

        .icon {
            transition: color 0.3s ease;
            position: absolute;
            top: 50%;
            right: 25px;
            cursor: pointer;
            color: #888;
            margin-top: -3px;
        
            &:hover {
              color: #333;
            }
          }
    }
    .reports-collapse-icon {
        position: absolute;
        padding: 5px; 
        bottom: 0; 
        right: 0;
        cursor: pointer;
    }
}


.insights-list{
  padding: 5px 5px;
  position: relative;
  font-size: 12px;
  &.active{
      outline: 2px solid #f99403;
      background-color: #DDD;
      &::after{
          display: none;
      }
  }
  &.restricted{
      outline: 2px solid #d94300;
      background-color: #DDD;
      &::after{
          display: none;
      }
  }
  &::after{
      content: "";
      width: 100%;
      height: 1px;
    //   background-color: #ddd;
      display: block;
      position: relative;
      top: 10px;
      float: left;
  }
  &:last-child{
      &::after{
          display: none;
      }
  }

  .device-icon{
      width: 30px;
  }
  .device-name{
      font-weight: bold;
      color: #4B4B4B;
  }
  .active-report {
      color: #4DAEF2;
  }
  .device-address{
      color: #888;
  }
  .show-less{
      position: absolute;
      top: 12px;
      right: 10px;
      color: #4DAEF2;
      stroke-width: 1;
      &.show-more{
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
      }
  }
  .euiDatePickerRange{
      border-radius: 3px;
      .euiFieldText.euiDatePicker{
         height: 26px;
         font-size: 12px;
         border-radius: 3px;
         &.euiFieldText--withIcon{
          padding-left: 30px;
         }
     }
     .euiFormControlLayoutIcons{
      left: 8px;
     }
  }

  .play-history {
      .animate-slider {
        left: -4px;
      }
      input[type="range"] {
        left: 12px;
        margin: 0;
        margin-left: 10px;
        width: 180px;
      }
      .delete-history {
          right: 0;
          width: 16px;
          top: 11px;
      }
  }
  .no-history-available {
      color: #e82d2d;
  }
  .text-count{
      position: absolute;
      top: 15px;
      left: 15px;
      /* right: 8px; */
      font-weight: 500;
      color: #000;
      border: 0px solid #4b4b4b;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
  }
  .text-count-white {
      color: #fff;
  }
  .custom-dropdown {
      .dropdown-toggle { 
          &::after{ 
            display: none;
          }
      }
      .btn {
          background: transparent;
          border: transparent;
          color: #666;
          padding: 0;
          &:active {
            color: #666;
            background-color: #fff;
          }
      }
  }
  .disabled {
      color: #c8c8c8;
      cursor: default;
  }
  .insights-list-item {
    .insight-report-icon {
        margin-right: 8px;
        border: 2px solid #070707;
        border-radius: 4px;
        padding-bottom: 3px;
        &.active {
            border: 2px solid #7800F7;
        }
    }
    .insight-report-accordian {
        button {
            font-size: 15px;
            font-weight: 600;
            color: #070707;
            padding: 0;
            border: 0;
            background-color: #fff;
            text-decoration: none;
            padding-bottom: 5px;
        }
        &.active-report button {
            color:#7800F7;
        }
        .insight-report-page {
            padding: 5px;
            font-size: 13px;
            font-weight: 600;
            color: #666666;
            cursor: pointer;
            &.active {
                color: #7800F7;
            }
        }
        .page-trash-icon {
            padding: 5px;
            cursor: pointer;
        }
    }
    .euiSpacer {
        block-size: 0px !important;
    }
  }
}

.modal-dialog .modal-content .modal-header {
  padding: 12px 20px 12px 20px !important;
}

.clone-modal-btns {
  text-align: right;
  margin-top: 15px;
  margin-right: 16px;
}

.no-data-found{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .insights-modal-dialog {
    .modal-dialog {
        top: 50%;
        position: fixed;
        left: 330px;
        right: 0;
        max-width: 400px;
        margin-top: -100px;
    }
    .right-content {
        text-align: center;
        margin-top: -15px;
        background-color: #fff;
        position: relative;
        .form-field{
            margin-bottom: 15px;
        }
        input.form-control {
            font-size: 12px;
            margin-bottom: 0;
            resize: none;
            border-radius: 0;
            border-color: #999;
            padding: 5px;
            background-color: transparent;
            width: 92%;
            border: 0;
            border-bottom: 1px solid #999;
            &:focus{
                box-shadow: none;
            }
        }
    }
    &.duplicate-report-modal {
        .modal-dialog {
            top: 35%;
            max-width: 500px;
            .tab-content {
                min-height: 250px;
                max-height: 250px;
            }
        }
        .right-content {
            text-align: left;
        }
        .pages-list {
            padding: 10px 0px 0px 5px !important;
            max-height: 200px;
            overflow-y: scroll;
            li {
                list-style-type: none;
                label {
                    margin: 0px 0px 10px 0px;
                }
            }
        }
    }
}

.progress-modal-dialog {
    .modal-dialog {
        top: 60%;
        position: fixed;
        left: 150px;
        right: 0;
        max-width: 100px;
        margin-top: -100px;
    }
    .modal-content {
        border: none !important;
        background-color: transparent !important;
    }
}

.grey-text {
    color: #a9a9a9;
    font-style: italic;
}

.reports-page {
    display: flex;
    height: 100%;
    min-height: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: relative;
    -webkit-box-flex: 1;
    .reports-list-section {
        height: 100%;
        overflow: auto;
        overflow-x: hidden !important;
        position: relative;
        width: 265px;
        min-width: 265px;
        -webkit-transition: width, left, right, 0.3s;
        transition: width, left, right, 0.3s;
        z-index: 1;
        &.collapsed {
            width: 0;
            min-width: 0;
        }
    }
    .vertical-divider {
        width: 5px;
        background-color: #122c44;
    }
    .report-view-section {
        width: 100%;
        height: 100%;
        // overflow: auto;
        min-height: 100%;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        position: relative;
        -webkit-box-flex: 1;
        flex-grow: 1;
        z-index: 1;
        .report-container {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
    .reports-expand-icon {
        position: fixed;
        bottom: 40px;
        left: 40px;
        cursor: pointer;
    }
}



