.sideMenu {
  width: 65px;
  background: #fff;
  text-align: center;
  height: 100vh;
  margin-right: 3px;
  
}

.sideIcon {
  font-size: 26px; 
  color: #D3D3D3;
  cursor: pointer;
}
