.tenants-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .logo {
    margin-bottom: 20px;
    text-align: center;

    img {
      width: 50px;
      height: auto;
    }
  }

  .title {
    margin-bottom: 20px;
    text-align: center;

    h1 {
      font-size: 2.5rem;
      font-weight: 600;
    }
  }

  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .search-wrapper {
      position: relative;
      width: 300px;

      input {
        color: #212529 !important;
        padding: 10px;
        font-size: 1rem;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding-right: 30px;
      }

      input:focus {
        color: #212529 !important;
      }
    }

    button.clear-btn {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: #ccc;
    }

    button.clear-btn:hover {
      color: #555;
    }
  }

  .clear-btn {
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .card-grid {
    width: 100%;
    padding: 20px;
  }
}

.tenants-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.tenant-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 150px;
  max-width: 150px;
  min-height: 150px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.tenant-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.tenant-card h3 {
  font-size: 1.5rem;
  margin-top: 10px;
}

.active-tenant {
  border: 1px solid #B821F7;
}


