.sideMenu {
  .menu-divider {
    height: 1px;
    margin: 8px 3px;
    background-color: #c8c8c8;
  }
  .report-icon {
    font-size: 24px;
    color: #070707;
    border-radius: 4px;
    cursor: pointer;
    &.icon-border {
      border: 2px solid #070707;
      &.active {
        color: #7800F7;
        border: 2px solid #7800F7;
      }
    }
    &.active {
      color: #7800F7;
    }
  }
}

.sideIcon {
  color: #666666;
  width: 65px;
  height: 40px;
  vertical-align: middle;
  display: flex;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  &.reports{
    margin-top: 5px;
  }
  &.callsInfo{
    position: relative;
    top: -2px;
  }
}

.menu-tooltip {
  .tooltip-inner {
    background-color: #122c44 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-top: 6px;
  }
}

.tooltip {
  opacity: 1 !important;
}

.tooltip-arrow {
  margin-top: 3px !important;
}

.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #122c44 !important;
}