.modal-dialog{
    .modal-content{
        border-radius: 0;
        border: 1px solid #ccc;
        background-color: #fff;
        background-clip: inherit;
        .modal-header{
            padding: 12px 20px 0 20px;
            border: 0;
            .modal-title{
                font-size: 16px;
            }
            .btn-close{
                font-size: 10px;
            }
            .close{
                padding: 0 0px 0 0;
                margin: 0 0 0 auto;
                position: relative;
                top: -4px;
                left: 5px;
                &:hover,&:focus{
                    outline: none;
                }
            }
        }
        .modal-body{
            padding: 20px;
            .delete-content{
                word-break: break-all;
            }
        }
        .modal-footer{
            padding: 35px;
            border: 0;
            margin: 0 auto;
            text-align: center;
        }
        .form-group{
            label{
                color: #000;
            }
            .form-control{
                border: 1px solid #999;
                border-radius: 0;
                background: transparent;
                width: 100%;
            }
        }
        
        .btn{
            height: 30px;
            line-height: 18px;
            font-weight: 500;
            padding: 0 30px;
            border-radius: 25px;
            background: transparent;
        }
        .btn-primary{
            border: 1px solid #b4b4b4;
            color: #fff;
            margin: 0;
            background-color: #0068a6;
            &:disabled{
                background-color: #999;
            }
        }
        .btn-light{
            color: #a49999;
            border: 1px solid #ccc;
            margin: 0 24px 0 0;
        }
    }
}