.user-profile-container {
  max-width: 720px;
  color: #000;
  margin: 25px 0px 0px 30px;
  .divider {
    width: 100%;
    height: 3px;
    background-color: #7800F7;
    margin: 5px 0px 25px 0px;
  }
  .form-label {
    font-weight: bold;
  }
  input {
    border: 0;
    border-bottom: 1px solid;
    border-radius: 0;
    background: none;
    background-color: #fff !important;
    max-width: 285px;
  }
  .capitalize {
    text-transform: capitalize;
  }
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px 32px 8px 12px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6"><path fill="rgb(108, 117, 125)" d="M0 0l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 12px center;
}

.save-btn.btn-primary {
  background-color: transparent !important;
  border-color: #7800F7 !important;
  color: #7800F7 !important;
  padding: 3px 20px !important;
  border-radius: 15px !important;
  margin-top: 10px !important;    
}