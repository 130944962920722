@import 'mixins';

$poiTtClr : #000;
.map-container{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.psap-map{
    position: relative;
    height: 100%;
    background-color: #FEFBF5 !important;
    overflow: hidden !important;
    .massgis-poi, .customLayer-poi {
        .mapboxgl-popup-content {
            background-color: #fff;
            color: #212529;
            .poi-label{
                text-transform: lowercase;
                display: inline-block;
                &::first-letter{
                    text-transform: capitalize;
                }
            }
            .poi-route {
                padding-bottom: 8px;
                border-bottom: 1px solid #666;
                margin: 10px 10px 0 10px;
                button {
                    border: 1px solid #fecbce;
                    padding: 1px 18px;
                    border-radius: 11px;
                    color: #fcf0f0;
                    line-height: 1.5;
                    background: #ea1b21;
                    &:hover {
                        opacity: 0.95
                    }                 
                }
            }
            .poi-container {
                max-height: 120px;
                overflow-y: auto;
            }   
            .aed-container  {
                margin-top: 10px;
            }
        }                       
    }
    .solacom-popup{
        ul{
            margin: 0;
            padding: 0 0 0 3px;
            li{
                list-style: none;
            }
        }
    }
}
.psap-sattelite-map{
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden !important;
    background-color: #FEFBF5 !important;
    .no-data-found{
        font-size: 16px;
    }
}
.map-divider {
    width: .6%;
    height: 100%;
    top: 0;
    position: absolute;
    bottom: 0;
    cursor: col-resize;
    background-color: #122c44;
    background-repeat: no-repeat;
  }
.map-divider-image{
    position: absolute;
    width: 69px;
    height: 69px;
    top: 50%;
    left: -32px;
    background-image: url('../assets/swipe_handler.png');
    z-index: 4;
}
.latlng-popup{
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0px auto;
    padding: 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    background: #000;
    opacity: .6;
    max-width: 170px;
    line-height: 18.5px;
    z-index: 10;
}
.psap-content{
    .map-style-box{
        position: absolute;
        z-index: 1;
        left: 10px;
        right: auto;
        top: 20px;
        background-color: #fff;
        .select-theme{
            min-width: 200px;
            .react-select__control {
                display: flex;
                border: 1px solid #ccc;
                height: 30px;
            }
            .react-select__single-value{
                font-weight: 500;
                color: #333;
            }
            .css-yk16xz-control{
                cursor: pointer;
                height: 30px;
                min-height: 30px;
                &:hover{
                    box-shadow: inherit;
                }
                .css-g1d714-ValueContainer{
                    height: 30px;
                    min-height: 30px;
                }
            }
            .css-1uccc91-singleValue{
                top:42%;
            }
            .react-select__indicator-separator{
                display: none;
            }
            .css-1pahdxg-control{
                height: 30px;
                min-height: 30px;
                box-shadow: none;
                border: 1px solid #ccc;
                border-radius: 0;
                cursor: pointer;                
            }
            .react-select__indicators{
                height: 30px;
                min-height: 30px;
                svg{
                    width: 15px;
                    color: #666;        
                }
            }
        }
    }
    .sync-polygons{
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 60px;
        .sync-btn{
            border-radius: 25px;
            padding: 3px 20px;
            margin-top: 10px;
            color: #0068ae;
            border-color: #0068ae;
            font-weight: 500;
            background-color: #fff;
            &:hover{
                background-color: #0068ae;
                color: #fff;
            }
        }
    }
    .react-select__menu{
        border-radius: 0;
        padding: 0;
        margin: 0;
        .react-select__option{
            cursor: pointer;
            overflow-y: auto;
            overflow-x: hidden;
            font-size: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .react-select__menu-list{
            max-height: 180px;
            overflow-y: auto;
            @include scrollbar();
        }
    }
    // .mapboxgl-ctrl{
    //     clear: inherit;
    // }
    .splitter-layout{
        .layout-pane{
            overflow: hidden;
        }
        .layout-splitter{
            width: 5px;
            background-color: #122c44;
        }
    }
}
.map-theme{
    display: flex;
    border: 1px solid #ccc;
    padding: 0 10px;
    height: 30px;
    align-items: center;
    border-right: 0;
    font-weight: 500;
    color: #666;
}
.marker{
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: #f38c21;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px #c0c0c0;
    cursor: pointer;
}
.search-marker{
    width: 33px;
    height: 33px;
    background: url('../assets/default-pin.svg');
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
}
.assignment-marker{
    width: 30px;
    height: 30px;
    background: url('../assets/assignment_marker.png');
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
}
.aed-marker{
    width: 24px;
    height: 24px;
    background: url('../assets/poi_aed.png');
}
.marker_popup{padding: 5px 5px}
.draw-polygon{
    cursor: pointer;
    position: absolute;
    right: 0;
    z-index: 4;
    width: 50px;
    height: auto;
    .icon-measurement{
        background: url('../assets/tool_measurement.png');
        right: 10px;
        position: absolute;
        top: 69px;
        width: 35px;
        height: 35px;
        &:hover{
            background: url('../assets/tool_measurement_hover.png');
        }
    }
    .icon-draw{
        background: url('../assets/tool_measurement_drawarea.png');
        right: 10px;
        position: absolute;
        top: 105px;
        width: 35px;
        height: 35px;
        &:hover, &.active {
            background: url('../assets/tool_measurement_drawarea_hover.png');
        }
    }
    .icon-download{
        background-color: #666563;
        margin: 0 auto;
        text-align: center;
        width: 34px;
        height: 34px;
        position: absolute;
        top: 141px;
        right: 10px;
        color: #d2d2d2;
        border-radius: 1px;
        align-items: center;
        display: flex;
        &.icon-upload{
            top:175.5px;
            background-color: inherit;
            background: url('../assets/floor_upload_icon_default.svg');
            &:hover{
                background: url('../assets/floor_upload_icon_hover.svg');
                background-color: #3a3a39;
            }
        }
        &:hover, &.active{
            background-color: #3a3a39;
            color: #0aaff8;
        }
        svg{
            height:29px;
            width: 22px;
            margin: 0 auto;
        }
    }
}
.polygons{
    background-color:#fff;
    position: absolute;
    left:250px;
    bottom:0;
}
.polygon{
    border-top: 1px solid #808080;
}
// .options-icon{
//     background: url('../assets/icon_layer_off.png');
//     position: absolute;
//     right: 10px;
//     bottom: 136px;
//     z-index: 3;
//     width: 47px;
//     height: 47px;
// }
.options-icon-content{
    position: absolute;
    bottom: 115px;
    right: 65px;
    padding: 15px;
    background: #ffffff;
    border: 1px solid #ccc;
    box-shadow: 3px 3px 7px rgba(0,0,0,0.25);
    width: 250px;
    z-index: 1;
    svg{
        position: absolute;
        right: 10px;
        top:10px;
        color: #666;
        path{
            stroke-width: 1;
        }
    }
    .text{
        display: inline-block;
        font-size: 16px;
        padding-right: 10px;
        min-width: 100px;
    }
}
.bounds-icon{
    background: url('../assets/btn_locate_off.png');  
    position: absolute;
    right: 10px;
    bottom: 120px;
    z-index: 4;
    width: 36px;
    height: 36px;
	&:hover{
        background: url('../assets/locate_hover.png');
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .map-style-box{
        right:60px;
    }
    // .options-icon{
    //     background: url('../assets/icon_layer_off-sm.png');
    //     width: 29px;
    //     height: 29px;
    //     bottom: 101px;
    // }
    // .bounds-icon{
    //     background: url('../assets/btn_locate_off_sm.png');  
    //     width: 29px;
    //     height: 29px;
    //     bottom: 136px;
    // }
    .options-icon-content{
        bottom: 70px;
        right: 50px;
    }
}

/* search styles */
.search-container{
    position: absolute;
    z-index: 4;
    display: flex;
    align-items: baseline;
    height: 26px;
    top: 12px;
    left: 12px;    
    width: 224px;
    max-width: 224px;
    .searchLayout{
        position: absolute;
        left: 230px;
        z-index: 1;
        display: flex;
        align-items: center;
        box-shadow: 0 0 3px #666;
        .ssbtn{
            height: 28px;
            width: 30px;
            border:0;
            text-align: center;
            margin: 0 auto;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .poi-icon{
                width: 10px;
            }
            .intersect-icon{
                width: 14px;
                margin-bottom: 2px;
                margin-left: 1px;
            }
            .w3w-icon {
                width: 15px;
                margin-bottom: 1px;
            }
        }
    }
    .spinner-border{
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        bottom: 0;
        margin: 0 auto;
        margin-top: -18px;
    }
    .spinner-border-sm{
        right: 5px !important;
        left: auto !important;
        margin-top: -6px !important;
        z-index: 1;
    }
    .list-container{
        width:260px;
        border-radius: 3px;
        overflow: hidden;
        border: 1px solid #666;
        margin-top: 4px;
        font-size: 14px;
        .poi-text{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .search-list{
        margin-top: 0px;
        overflow-y:auto;
        overflow-x: hidden;
        background-color: #fff;
        border-top: 1px solid #666;
        scroll-behavior: smooth;
        &.default-view{
            max-height: 200px;
        }
        &.show-list{
            max-height: 400px;
        }
        .list-group-item{
            padding: 6px 11px;
            display: block;
            font-weight: normal;
            color: #111;
            margin: 0;
            &.active{
                color: #0077e5;
                background-color: #fff;
                margin-top: 0;
            }
        }
    }
    .list-group-heading{
        background-color: #0077e5;
        color: #fff;
        padding: 0 11px;
        height: 28px;
        line-height: 28px;
        font-weight: 500;
        svg{
            font-size: 15px;
            margin-left: 8px;
        }
        .disable-arrw{
            color: rgba(255, 255, 255, 0.4);
            pointer-events: none;
        }
    }
    .w3w-search-field {
        width: 225px !important;
        background: #fff;
        outline: none;
        border: 1px solid #666;
        padding: 3px 21px 3px 8px;
        color: #333;
        box-shadow: 0 0 3px #666;
        border-radius: 2px;
        &:focus {
            color: #333 !important;
        }
        &::placeholder {
            color: #999 !important;
        }
    }
    .react-autosuggest__input{
        width: 225px;
        background: white;
        outline: none;
        border: 1px solid #666;
        padding: 3px 21px 3px 8px;
        color: #333;
        box-shadow: 0 0 3px #666;
        border-radius: 2px;
        &::placeholder {
            color: #999 !important;
        }
        &:focus{
            color: #333 !important;
        }
    }
    .react-autosuggest__container{
        position: relative;
    }
    .react-autosuggest__suggestions-container{
        margin-top: 3px;
        position: absolute;
        margin-left: 0;
        z-index: 3;
        background: #fff;
        width: 260px;
        ul{
            margin: 0;
            padding: 0;
            border: 1px solid #ccc;
            padding: 5px 8px;
            max-height: 195px;
            overflow-y: auto;
            overflow-x: hidden;
            @include scrollbar();
            li{
                list-style: none;
                color: #333;
                font-weight: 500;
                padding: 3px 2px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;
                &:hover{
                    color: #0068ae;
                }
            }
        }
    }
}
.poi-close-icon{
    position: absolute;
    top: 9px;
    right: 3px;
    stroke-width: .9;
    color: #6c757da3;
    z-index: 1;
}
.auto-zoom-icon{
    position: absolute;
    right: 10px;
    bottom: 165px;
    z-index: 4;
    width: 36px;
    height: 36px;
    &.enable-auto-zoom{
        background: url('../assets/autozoomout_able.png'); 
        &:hover{
            background: url('../assets/autozoomout_able_hover.png');  
        }
    }
    &.disable-auto-zoom{
        background: url('../assets/autozoomout_disable.png'); 
        &:hover{
            background: url('../assets/autozoomout_disable_hover.png');  
        }
    }
}
.indoorMap-list{
    position: absolute;
    right: 60px;
    border-bottom: hidden;
    background: #fff;
    display: inline-flex;
    z-index: 1;
    top: 16px;
    padding: 0 5px;
    border-radius: 5px;
    // box-shadow: 0 1px 4px rgba(0,0,0,.3);
    color: #70757a;
    border: 1px solid #cdcdcd;
    .active{
        color: #0077e5;
    }
    .floor-list{
        padding: 3px 0 3px 5px;
        &:first-child{
            color: #333333;
            border-right: 1px solid #cdcdcd;
            padding-right: 7px;
        }
    }
    .indoor-dropdown{
        position: absolute;
        background: #fff;
        right: 0;
        top: 30px;
        border-radius: 5px;
        color: #70757a;
        border: 1px solid #cdcdcd;
        padding: 3px 0;
        max-height: 160px;
        overflow: auto;
        .indoor-list{
            padding: 3px 5px;
        }
    }
    .indoor-dropdown-icon{
        margin: 0 5px 0 10px;
        position: relative;
        top: -1px;
    }
}
