.calls-info-panel {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;

    .mobile-search-box {
        position: absolute;
        top: 5px;
        left: 5px;
        input {
            padding: 9px 10px 10px 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            color: #000 !important;
            font-size: 14px;
            outline: none;
            width: 240px;
            transition: all 0.3s ease;
            &:focus {
                border-color: #7800F7;
            }
        }

        .search-spinner {
            position: absolute;
            top: 40%;
            right: 15px;
        }

        .icon {
            position: absolute;
            top: 50%;
            right: 10px;
            cursor: pointer;
            color: #888;
            margin-top: -7px;

            &:hover {
                color: #333;
            }
        }
    }
    .call-list-count{
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #333 !important;
        font-size: 24px;
        position: fixed;
        bottom: 0;
        background: #fff;
        left: 72px;
        right: 0;
        bottom: 60px;
        padding: 8px 10px;
        font-weight:500;
        text-align: center;
        max-width: 550px;
        margin: 0 auto;
    }
    .call-type{
        color: #999;
        font-size: 12px;
        letter-spacing: 0.2px;
    }

    .loading-spinner{
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #333 !important;
        position: fixed;
        bottom: 0;
        background: #fff;
        left: 67px;
        right: 0;
        bottom: 60px;
        padding: 8px 10px;
        text-align: center;
        max-width: 163px;
        margin: 0 auto;
    }

    .calls-error{
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #FFFFFF !important;
        position: fixed;
        bottom: 0;
        background: #b60404;
        left: 72px;
        right: 0;
        bottom: 60px;
        padding: 8px 10px;
        text-align: center;
        max-width: 163px;
        margin: 0 auto;
    }
}
.call-info-label{
    color: #999;
}

.call-bounds-icon {
    position: fixed;
    bottom: 146px;
    right: 14px;
}
.donut-popup{
    min-width: 120px;
    h4{
        font: normal normal normal 14px/14px Helvetica;
        letter-spacing: 0.3px;
        color: #7800f7;
        text-align: center;
        padding-bottom: 3px;
        border-bottom: 1px solid #888888;
    }
    .lable-type{
        min-width: 90px;
        display: inline-block;
        color: #666;
        font-size: 12px;
        letter-spacing: 0.2px;
        font-weight: 500;
    }
    .count-list{
        min-width: 35px;
        font-size: 11px;
        color: #99999B;
        font-weight: bold;
    }
}