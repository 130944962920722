
.mapboxgl-popup{
        z-index: 3;
}
.mapboxgl-popup-content{
        pointer-events: auto;
        min-width: 200px;
        padding: 10px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        letter-spacing: 0px;
        color: #333333;
        .mapboxgl-popup-close-button{
            font-size: 16px;
            color: #666;
            padding-right: 8px;
            &:hover{
                background-color: transparent;
                outline: none;
            }
        }
}
.marker_popup{
        padding:0;
        &.address-marker{
            margin: 0;
            .pagination-reportbtn{
                margin-right: 5px;
            }
        }
        .close-popup{
            position: absolute;
            top: 0;
            right: 5px;
            svg{
                color: #666;
                stroke-width: 1;
            }
        }
        .title{
            .active{
                color: #0068ae;
            }
            b{
                padding-right: 21px;
            }
        }
}
.pagination{
        justify-content: flex-end;
        color: #000;
        font-weight: 500;
        // padding-right: 10px;
        margin-bottom: 0;
        height: 17px;
        li{
            padding: 0 4px;
            // width: 15px;
            a{
                color: #333;
                font-weight: 600;
                &:hover{
                    text-decoration: none;
                }
                &.arrow-right, &.arrow-left{
                    font-size: 0;
                    position: relative;
                    &::after{
                        content: '>';
                        font-size: 14px;
                        color: #0068ae;
                    }
                }
                &.arrow-left{
                    font-size: 0;
                    position: relative;
                    &::after{
                        content: '<';
                        font-size: 14px;
                        color: #0068ae;
                    }
                }
            }
            &.active{
                a{
                    color: #0068ae;
                }
            }
            &.disabled{
                a{
                    &.arrow-right, &.arrow-left{
                        &::after{
                            color: #999;
                            cursor: not-allowed;
                        }
                    }
                }
            }
            &:last-child{
                display: none;
            }
        }
}
.report-menu{
    border-bottom: 1px solid #707070;
    padding-bottom: 5px;
    .report-btn{
        width: 61px;
        height: 21px;
        background: #2F78DD 0% 0% no-repeat padding-box;
        border-radius: 3px;
        font: normal normal normal 12px/14px Helvetica;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-align: center;
        line-height: 20px;
    }
}



