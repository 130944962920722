.edit-polygon-dialog{
    .modal-dialog{
        top: 50%;
        position: fixed;
        left: 330px;
        right: 0;
        max-width: 400px;
        margin-top: -100px;
    }
    .right-content{
        text-align: center;
        margin-top: -15px;
        background-color: #fff;
        position: relative;
        .form-field{
            margin-bottom: 15px;
        }
        .close-panel{
            position: absolute;
            color: #999;
            stroke-width: 1;
            right: 15px;
            top: 8px;
        }
        .form-control{
            font-size: 12px;
            margin-bottom: 0;
            resize: none;
            border-radius: 0;
            border-color: #999;
            padding: 5px;
            background-color: transparent;
            width: 92%;
            &:focus{
                box-shadow: none;
            }
            &.polygon-name{
                border: 0;
                border-bottom: 1px solid #999;
            }
        }
        .icon-trash{
            position: absolute;
            right: 0;
            top: 14px;
        }
        .geofenceText {
            text-align: left;
            font-size: 12px;
        }
    }
}