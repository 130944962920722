html {
  color: $color-white;
  font-size: $font-size-5;
  box-sizing: border-box;
  background-color: #082441;
}

body {
  // The UI is 100% height so prevent bounce scroll on OSX
  // overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

p {
  margin-top: $margin-2;
  margin-bottom: $margin-2;
  line-height: 1.3;
}

h1 {
  font-size: $font-size-2;
  margin-bottom: $margin-3;
  font-weight: bold;
}

h2 {
  font-size: $font-size-3;
  margin-bottom: $margin-3;
  font-weight: bold;
}

h3 {
  font-size: $font-size-4;
  margin-bottom: $margin-3;
  font-weight: bold;
}

h4 {
  font-size: $font-size-5;
  margin-bottom: $margin-3;
}

input:focus,
select:focus {
  color: $color-white !important;
  // outline: #8e8e8e auto 1px !important;
}

.overlay-index{
  z-index: 1;
}

.clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

a {
  color: white;
}

.hide{
  display: none !important;
}
.mapboxgl-ctrl-bottom-right{
  // .mapboxgl-ctrl-attrib.mapboxgl-ctrl{
  //   position: absolute;
  //   bottom: 5px;
  //   right: 3px;
  //   width: 260px;
  //   margin: 0 !important;
  // }
  .mapboxgl-ctrl-attrib.mapboxgl-compact{
    min-height: 24px;
    // right: 10px;
    top: 6px;
  }
}
.mapboxgl-ctrl-logo{
  display: none !important;
  margin:1px;
}
.comtech-logo{
  position: absolute;
  bottom: 6px;
  left: 10px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  z-index: 1;
  img{
    width: 90px;
  }
}
