@import 'vars';
@import 'base';
@import 'header';

.main {
  height: calc(100% - 92px);
  flex-grow: 1;
}

.navbar{
  padding: .5rem 0 !important;
}

// .container-fluid{
//   padding-right: 30px !important;
// }

.header, .main, .footer {
  flex-shrink: 0;
}
.psap-content{
  position: fixed;
  top:50px;
  bottom:35px;
  left: 275px;
  right: 5px;
  .spinner-border{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    margin: 0 auto;
    margin-top: -18px;
  }
}
.text-primary{
  color: rgb(120, 0, 247) !important;
}

.profile-img.dropdown-toggle{
  margin: 9px 35px 10px 15px !important;
}

.profile-img.dropdown-toggle::after{
   content: " " !important;
      // height: 60px;
      // color: transparent;
      // width: 40px;
      // background: url(../assets/icon_user.svg) no-repeat;
      // position: absolute;
      // background-size: 60px;
      // margin-top: -12px;
      border:0;
}

.profile-img{ 
  .user-name-icon{
    width: 32px;
    height: 32px;
    display: flex;
    position: absolute;
    right: 0;
    top: 3px;
    background: #0068aE;
    text-align: center;
    color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: normal;
  }
  .dropdown-menu .dropdown-item{
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    padding: 5px;
    font-weight:600;
    color: #666;
    font-size: 0.875rem /* 14px */;  
  }
}

.profile-img a.dropdown-item:hover {
  background: none;
  color: #0068aE !important;  
}

.choose-station{
  .comtech-btn {
    margin-top: 65px;
  }
}

.comtech-btn {
  border-radius: 25px !important;
  white-space: nowrap;
  vertical-align: middle !important;
  text-align: center;
  padding: 0 5px !important;
  font-weight:600;
}

.comtech-btn:active, .comtech-btn:focus{
  outline: none;
}

.comtech-btn.btn-lg {
  min-width: 125px;
  min-height: 40px;
  font-size: 1rem /* 16px */;
}

.comtech-btn.btn-sm {
  min-width: 85px;
  min-height: 28px;
  line-height: 28px !important;
  font-size: 0.875rem /* 14px */;
}

.comtech-btn-primary{
    border: 1px solid #0068a6;
    background-color: #0068a6;
    color: #fff;
}

button[type="submit"]:disabled {
  border: 1px solid #ccc;
  background-color: #ccc;
  &:focus, &:hover{
    border: 1px solid #ccc;
    background-color: #ccc;
  }
}

.comtech-btn-primary:hover {
    border: 1px solid #004060;
    background-color: #004060;
    color: #fff;
}

.comtech-btn-primary:active:hover {
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
}
  
.comtech-btn-secondary {
  border: 1px solid #0068a6;
  background-color: #fff;
  color: #0068a6;
}

.comtech-btn-secondary:hover, .comtech-btn-secondary:focus {
    border: 1px solid #004060;
    background-color: #fff;
    color: #004060;
  }
  .comtech-btn-secondary:active:hover {
    border: 1px solid #000;
    background-color: #000;
    color: #fff;
  }
  
  a {
      color: #0068a6;
  }

  .form-control:active, .form-control:focus{
    color:#000 !important;
  
  }
  .form-control{
    &.is-invalid{
      background-image: none !important;
    }
  }
  .form-group .form-control{
    width: 329px;
    border: 1px solid #666666;
    color: #000 !important;
  }

  .form-group label{
    margin-bottom: 0;
    color: #999
  }
  .form-group{
    margin-bottom: 1rem;
  }

  .req{
    color:#ff0000;
  }
  .hand-cursor{ cursor: pointer;}
  .footer {
    bottom:0;
    width: 100%;
    background-color: #122c44;
    min-height: 35px;
    font-weight: normal;
    line-height: 35px;
    font-size: 12px;
    color: #fff;
    .footer-links{
      position: absolute;
      left: 50%;
      right: 0;
      margin: 0 auto;
      text-align: center;
      // max-width: 250px;
    }
    .footer-copyright{
      padding-left: 10px;
    }
  }
  .comtech-content{
    margin-bottom: 60px;
  }
  .footer-fix{
    position: fixed;
  }
  .footer .link a {
    color:#fff;
    border-right: 1px solid #6e7b89;
    padding: 0 8px;
  }
  
  .footer .last-link a {
    color:#fff;
    padding-left: 8px;
  }

  .sign-in{
    margin-top: 20px;
    background-color: #fff;
    min-height: 240px;
    max-width: 329px;
  }

  .sign-up p{
    color: #000 !important;
    font-size: 14px !important;
  }
  .container{
    .alert-danger {
      color: #c10303;
      background-color: #fee5e2;
      border-color: #fcaca5;
      height: 50px;
      line-height: 15px;
    }
    .otp-success{
      max-width: 329px;
    }
  }
  .error-msg{
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .small-text{
    font-size: 12px;
  }
  .region-down-signout {
    .modal-header {
      padding: 0.55rem 1rem !important;
      border-bottom: 1px solid #dee2e6 !important;
    }
    .h4, h4 {
      font-size: 1.2rem;
    }
    .modal-body {
      padding: 1rem !important;
    }
    .modal-footer {
      margin: 0 !important;
      padding: 0.75rem 1rem 1.2rem 0.75rem !important;
    }
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-10 {
    margin-top: 10px;
  }
  
@media (min-width: 1200px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
    width: 1140px;
  }

  body{
    overflow-x: hidden;
  }
}

@media only screen and (max-width:1200px){
  .footer{
    .footer-links{
      left: auto;
    }
  }
}

@media only screen and (max-width:1000px){
  .footer{
    .footer-links{
      left: auto;
      right: 10px;
    }
  }
}
  
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .navbar {
    padding-left: 0;
    padding-right: 0;
  }
}

.mt-10 {
  margin-top: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.ml-6 {
  margin-left: 6px;
}
.mr-6 {
  margin-right: 6px;
}
.left-side {
  width: 65px;
  background: #fff;
  text-align: center;
  margin-right: 3px;
  position: fixed;
  left: 4px;
  right: 0;
  top: 50px;
  bottom: 35px;
}
.right-section {
  background: #fff;
    position: fixed;
    left: 72px;
    right: 5px;
    top: 50px;
    bottom: 35px;
}
.fullpage-section {
  background: #fff;
  position: fixed;
  left: 0;
  right: 5px;
  top: 50px;
  bottom: 35px;
  overflow-y: auto;
}
.sidebar-section-open {
  background: #fff;
  margin-right: 3px;
  position: fixed;
    left: 72px;
    right: 0;
    top: 50px;
    bottom: 35px;
    z-index: 11;
    // overflow: auto;
    &.right-auto {
      right: auto;
    }
}
.content-section{
  background-size: cover;
  color: #fff;
  background: #fff;
  position: fixed;
  left: 72px;
  right: 3px;
  top: 50px;
  bottom: 35px;
  z-index: 1;
  &.map {
    left: 72px;
  }
} 
.map-top-section {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 11;
}
.buttonsPadding {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 12px;
}
.my-custom-control{
  left: 72px !important;
  bottom: 135px !important;
}
.logo-text {
  font-size: 20px;
  font-weight: 500;
}
.mapboxgl-ctrl-attrib{
  a{
    &:hover{
      color: #000;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}