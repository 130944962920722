.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-top-left, .mapboxgl-ctrl-top-right{
    z-index: 4 !important;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-compass{
    position: absolute;
    right: 0;
    top: 35px;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon{
    background-image:url("../assets/compass_dark.png") !important;
    height: 36px;
    width: 36px;
    margin:1px;
}
.mapboxgl-ctrl-group:not(:empty) {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important; 
    box-shadow: none !important; 
}
.mapboxgl-ctrl.mapboxgl-ctrl-group {
    background: transparent !important;
    position: absolute;
    bottom: 15px;
    right: 0px;
}
.mapboxgl-ctrl-group > button.mapboxgl-ctrl-compass {height: 36px; width:36px;}
.mapboxgl-control-container .mapboxgl-ctrl-group button{
    height: 36px;
    width: 36px;
}
.mapboxgl-ctrl button:not(:disabled):hover {background-color: transparent !important}
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
     background-image:url("../assets/zoom_in.png") !important; height:36px; width:36px; margin-left: 0;}
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon { 
    background-image:url("../assets/zoom_out.png") !important;
    height: 36px;
    width: 36px;
    margin-top: 3px;
	margin-left: 0;
 }
 .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon:hover {
    background-image:url("../assets/zoom_in_hover.png") !important;
 }
 .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon:hover{ 
    background-image:url("../assets/zoom_out_hover.png") !important;
 }
/* .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl{
    margin: 0 15px 15px 0;
} */
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib.mapboxgl-compact{
    min-height: 24px;
}
.mapboxgl-ctrl-attrib.mapboxgl-compact .mapboxgl-ctrl-attrib-button:focus, .mapboxgl-ctrl-attrib.mapboxgl-compact .mapboxgl-ctrl-group button:focus{
    box-shadow: none;
    outline: none;
}
.mapboxgl-ctrl-group button:focus{box-shadow: none !important}
.mapboxgl-ctrl-group button+button { border-top: none !important;}
.mapboxgl-ctrl-scale {background-color: rgba(255,255,255, 0.6); border: 1px solid #333; border-top: #333; right:55px; position: absolute; bottom: 15px;}

/* .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl{
    margin: 0 8px 35px 0;
} */
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in{
    margin-bottom: 5px;
}
  
@media (min-width: 768px) and (max-width: 1024px) {
    .mapboxgl-ctrl button.mapboxgl-ctrl-compass{
        top: 25px;
    }
    .mapboxgl-ctrl.mapboxgl-ctrl-group {
        bottom: 24px;
    }
    .psap-map .mapboxgl-ctrl-top-right{
        top:10px;
    }
    /* .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon{
        background-image:url("../assets/compass_dark.png") !important;
        height: 30px;
        width: 30px;
    } */
    /* .mapboxgl-control-container .mapboxgl-ctrl-group button{
        height: 29px;
        width: 29px;
    } */
    /* .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon { background-image:url("../assets/zoom_in.png") !important; height:29px; width:29px;outline:none; margin-left: 0;}
    .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon { background-image:url("../assets/zoom_out.png") !important; height:29px; width:29px; margin-top:0;outline:none; margin-left: 0;} */
}
