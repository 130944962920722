.about-us{
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: auto;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding-bottom: 40px;
    background-color: #fff;
    color: #212529;
    .border-bottom{
        border-color: #122c44 !important;
    }
    h3{
        font-size: calc(1.3rem + .6vw);
        font-weight: 500;
    }
    h4{
        font-size: calc(1.275rem + .3vw);
    }
    p{
        margin-top: 0;
        margin-bottom: 1rem;
    }
    a{
        color: #0d6efd;
    }
}
.about-logo{
    margin-left: -3px;
}
