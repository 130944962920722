$color-black: #191b20;
$color-gray: #222429;
$color-midgray: #303237;
$color-lowgray: #a4a4a4;
$color-white: #f0f0f0;
$color-red: #cf4a4a;
$color-green: #53b972;
$margin-1: 3px;
$margin-2: 5px;
$margin-3: 10px;
$margin-4: 30px;
$margin-5: 40px;
$font-size-1: 24px;
$font-size-2: 20px;
$font-size-3: 18px;
$font-size-4: 16px;
$font-size-5: 14px;
$font-size-6: 12px;
$font-family: Roboto, sans-serif;
